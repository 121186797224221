import * as React from "react"
import Layout from "../../components/layout"
import PageHeader from "../../components/page-header"
import TestimonialSlider from "../../components/testimonial-slider"
import { StaticImage } from "gatsby-plugin-image"

const InteriorDesignPage = () => {
  return (
    <Layout>
      <PageHeader title="Interior Design" breadcrumbs="Home /  Interior Design" />
      <main className={'mx-auto max-w-screen-xl w-full px-6 py-8 lg:py-16 relative'}>
        <div className={'lg:grid lg:grid-cols-2 lg:gap-16'}>
          <div>
            <div className={'lg:sticky lg:top-16 space-y-8'}>
              <StaticImage
                src="../../images/interior-design.jpeg"
                alt=""
                layout="fullWidth"

              />
              <StaticImage
                src="../../images/interior-design-1.jpeg"
                alt=""
                layout="fullWidth"
              />
            </div>
          </div>
          <div>
            <div className={'lg:sticky lg:top-16'}>
              <div className={'prose-lg'}>
                <h2>Inspiring & Practical</h2>
                <p className={'lead'}>We deliver inspirational interiors that reflect our clients’ personalities and fit with how they wants to live. We pride ourselves on designing practical solutions for whatever the project requires.</p>
                <p>It is with meticulous planning and great attention to detail that we achieve your goals. We work with the best craftsmen to create seamless, well executed and beautifully finished projects, delivered on time and on budget.</p>
                <p>We work primarily in Northern Ireland, but occasionally further afield, on schemes that range from small intimate spaces to large renovation and refurbishment projects.</p>
                <p>Our design skills encompass interior design, space planning, creating bespoke furnishings, joinery and lighting design schemes. We source accessories and work with salvaged pieces to create one-off living environments.</p>
                <p>When we aren’t in the showroom or on a project, we spend our time at trade shows so we can offer our clients the best in what is new and exquisite.</p>
                <p>Don’t take our word for it.</p>
              </div>
              <TestimonialSlider />
            </div>
          </div>
        </div>
      </main>
      <main className={'mx-auto max-w-screen-xl w-full px-6 py-8 lg:py-16 relative'}>
        <div className={'space-y-8 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8'}>
        <StaticImage
          src="../../images/interior-design-2.jpeg"
          alt=""
          layout="fullWidth"
        />
        <StaticImage
          src="../../images/interior-design-3.jpeg"
          alt=""
          layout="fullWidth"
        />
        <StaticImage
          src="../../images/interior-design-4.jpeg"
          alt=""
          layout="fullWidth"
        />
        </div>
      </main>

    </Layout>
  )
}

export default InteriorDesignPage

export const Head = () => (
    <>
    <title>Sofa & Chair Collection - Jo Andrews Interiors</title>
    <meta name="description" content="We are a long time supplier and stockist of Coachhouse furniture. However, in order to create a truly individual look we often design and create bespoke pieces to complement the design scheme." />
    </>
)
